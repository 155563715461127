<script>
/**
 * Shop-cart component
 */
import { ArrowLeftIcon } from "vue-feather-icons";
import Vue from "vue";
export default {
  components: {
    ArrowLeftIcon,
  },
  data() {
    return {
      userAccountValue: 1000,
      storageValue: 50,
      liveValue: 2,
      counter: 0,
      list: [],
    };
  },
  computed: {
    totalValue() {
      return (
        parseInt(this.userAccountValue) +
        parseInt(this.storageValue) +
        parseInt(this.liveValue)
      );
    },
  },
  mounted() {
    this.userAccountValue = this.$route.query.user;
    this.storageValue = this.$route.query.storage;
    this.liveValue = this.$route.query.live;

    this.list = [
      {
        id: 1,
        image: "images/shop/product/s1.jpg",
        title: "User Account",
        // price: 255,
        qty: this.userAccountValue + " Student",
        total: this.userAccountValue,
      },
      {
        id: 2,
        image: "images/shop/product/s3.jpg",
        title: "Storage",
        // price: 520,
        qty: this.storageValue + " GB",
        total: this.storageValue,
      },
      {
        id: 3,
        image: "images/shop/product/s6.jpg",
        title: "Live",
        // price: 160,
        qty: this.liveValue + " Channel",
        total: this.liveValue,
      },
    ];
  },
  methods: {
    increase(val) {
      this.list.map((product) => {
        if (product.id === val) {
          product.qty += 1;
        }
      });
    },
    decrement(val) {
      this.list.map((product) => {
        if (product.id === val) {
          while (product.qty > 0) {
            product.qty -= 1;
          }
        }
      });
    },
    hasHistory() {
      return window.history.length > 2;
    },
    proceedCheckout() {
      this.$router.push({
        name: "Payment",
        query: this.$route.query,
      });
    },
  },
};
</script>

<template>
  <div>
    <!-- Hero Start -->
    <section class="bg-invoice bg-light">
      <div class="container">
        <div class="row pt-sm-0 justify-content-center">
          <div class="col-lg-10">
            <a
              @click="hasHistory() ? $router.go(-1) : $router.push('/')"
              class="btn btn-outline-primary"
            >
              <arrow-left-icon size="1x" class="custom-class"></arrow-left-icon>
              Back to calculator
            </a>
          </div>
        </div>
        <div class="row pt-sm-0 justify-content-center">
          <div class="col-lg-10">
            <div class="card shadow rounded border-0">
              <div class="card-body">
                <div class="invoice-top pb-4 border-bottom">
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="logo-invoice mb-2">Confirm Order</div>
                    </div>
                  </div>
                  <!--end row-->
                </div>
                <div class="invoice-middle py-4">
                  <h5>Order Details</h5>
                  <div class="row mb-0">
                    <div class="col-md-8 order-2 order-md-1">
                      <dl class="row text-align-end">
                        <dt class="col-md-3 col-5 font-weight-normal pb-0">
                          Oder No. :
                        </dt>
                        <dd class="col-md-9 col-7 text-muted mb-0 pb-0">
                          land45845621
                        </dd>

                        <dt class="col-md-3 col-5 font-weight-normal pb-0">
                          Name :
                        </dt>
                        <dd class="col-md-9 col-7 text-muted mb-0 pb-0">
                          Calvin Carlo
                        </dd>

                        <dt class="col-md-3 col-5 font-weight-normal pb-0">
                          Phone :
                        </dt>
                        <dd class="col-md-9 col-7 text-muted mb-0 pb-0">
                          (+45) 4584-458-695
                        </dd>
                      </dl>
                    </div>

                    <div class="col-md-4 order-md-2 order-1 mt-2 mt-sm-0">
                      <dl class="row mb-0" style="text-align: end">
                        <dt class="col-md-5 col-5 font-weight-normal pb-0">
                          Date order:
                        </dt>
                        <dd class="col-md-7 col-7 text-muted mb-0 pb-0">
                          15th Oct, 2019
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>

                <div class="invoice-table pb-4">
                  <div class="table-responsive bg-white shadow rounded">
                    <table class="table mb-0 table-center invoice-tb">
                      <thead class="bg-light">
                        <tr>
                          <th scope="col" class="text-left">No.</th>
                          <th scope="col" class="text-left">List</th>
                          <th scope="col">Qty</th>
                          <!-- <th scope="col">Rate</th> -->
                          <th scope="col">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) of list" :key="index">
                          <th scope="row" class="text-left">{{ index + 1 }}</th>
                          <td class="text-left">{{ item.title }}</td>
                          <td>{{ item.qty }}</td>
                          <td>THB {{ item.total }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-lg-4 col-md-5 ml-auto">
                      <ul class="list-unstyled h5 font-weight-normal mt-4 mb-0">
                        <li class="text-muted d-flex justify-content-between">
                          Subtotal :<span>THB {{ totalValue }}</span>
                        </li>
                        <li class="text-muted d-flex justify-content-between">
                          Taxes :<span> THB {{ totalValue * 0.07 }}</span>
                        </li>
                        <li class="d-flex justify-content-between">
                          Total :<span>{{
                            totalValue + totalValue * 0.07
                          }}</span>
                        </li>
                      </ul>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>

                <div class="invoice-footer border-top pt-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="text-sm-right text-muted text-center">
                        <a @click="proceedCheckout" class="btn btn-primary">
                          Proceed to checkout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

    <!-- <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Shopping Cart</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Landrick</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/index-shop">Shop</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Cart
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive bg-white shadow">
              <table class="table table-center table-padding mb-0">
                <thead>
                  <tr>
                    <th class="py-3" style="min-width: 300px">List</th>
                    <th class="text-right py-3" style="min-width: 160px">
                      Quantity
                    </th>
                    <th class="text-center py-3" style="min-width: 160px">
                      Total
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) of list" :key="index">
                    <td>
                      <div class="d-flex align-items-center">
                        <h6 class="mb-0 ml-3">{{ item.title }}</h6>
                      </div>
                    </td>
                    <td class="text-right">
                      <h6 class="mb-0 ml-3">{{ item.qty }}</h6>
                    </td>
                    <td class="text-center font-weight-bold">
                      THB {{ item.total }}.00
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-6 mt-4 pt-2">
            <button
              @click="hasHistory() ? $router.go(-1) : $router.push('/')"
              class="btn btn-primary"
            >
              Back to Calculator
            </button>
          </div>
          <div class="col-lg-4 col-md-6 ml-auto mt-4 pt-2">
            <div class="table-responsive bg-white rounded shadow">
              <table class="table table-center table-padding mb-0">
                <tbody>
                  <tr>
                    <td class="h6">Subtotal</td>
                    <td class="text-center font-weight-bold">
                      THB {{ totalValue }}
                    </td>
                  </tr>
                  <tr>
                    <td class="h6">Taxes</td>
                    <td class="text-center font-weight-bold">
                      THB {{ totalValue * 0.07 }}
                    </td>
                  </tr>
                  <tr class="bg-light">
                    <td class="h6">Total</td>
                    <td class="text-center font-weight-bold">
                      THB {{ totalValue + totalValue * 0.07 }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-4 pt-2 text-right">
              <a @click="proceedCheckout" class="btn btn-primary">
                Proceed to checkout
              </a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>
